import { render, staticRenderFns } from "./assign-model.vue?vue&type=template&id=05563f1e&scoped=true"
import script from "./assign-model.vue?vue&type=script&lang=js"
export * from "./assign-model.vue?vue&type=script&lang=js"
import style0 from "./assign-model.vue?vue&type=style&index=0&id=05563f1e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05563f1e",
  null
  
)

export default component.exports