<template>
  <div class="content clearfix">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="nf-tabs">

      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          v-show="index!==4 || isAssign"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <div>
            <el-badge :value="backlog" class="item" v-if="nav.name === '待办管理' && backlog != '0'">
              <i v-if="nav.name === '待办管理'" class="icon icon-await"></i>
            </el-badge>
            <i v-if="nav.name === '待办管理' && backlog == '0'" class="icon icon-await"></i>
            <i v-if="nav.name === '办文管理'" class="icon icon-processing"></i>
            <i v-if="nav.name === '拟文管理'" class="icon icon-draft"></i>
            <i v-if="nav.name === '全部公文'" class="icon icon-all"></i>
            <i v-if="nav.name === '指派管理'" class="icon icon-zp"></i>
            <span>{{nav.name}}</span>
           </div>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        
    <!--  start-->
    <div class="operate">
      <el-form :inline="true" v-for="(item,index) in form" :key="index"  class="filter-form">
        <div v-if="index == currentTabsNav">
          <el-form-item label="紧急程度:">
            <el-select class="form-row" v-model="item.pressing" placeholder="紧急程度">
              <el-option label="紧急" value="2"></el-option>
              <el-option label="急" value="1"></el-option>
              <el-option label="一般" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公文状态:">
            <el-select class="form-row" v-model="item.status" placeholder="公文状态">
              <el-option label="已发送" :value="2"></el-option>
              <el-option label="已办结" :value="3"></el-option>
              <el-option label="已驳回" :value="4"></el-option>
              <el-option label="已删除" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模糊搜索:">
            <el-input class="form-row" v-model="item.keyword" placeholder="模糊搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      </div>
     <!-- 搜所--end -->
    <div v-show="currentTabsNav === 0">
      <el-table
        ref="multipleTable"
        stripe
        :data="tableDataOne"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column header-align="center" label="公文标题">
          <template slot-scope="scope">
            <span class="nf-tag nf-tag-jinji" v-if="scope.row.emergencyLevel == '1' || scope.row.emergencyLevel == '2'">{{scope.row.emergencyLevel == '1' ? '急' : '紧急'}}</span>
            <span    @click="toDetails(scope.row.id, scope.row.documentType, scope.row.documentArea, scope.row.documentStatus)" class="table-cont-title">{{ scope.row.documentTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="当前办文节点" align="center" width="250">
          <template slot-scope="scope">
            <span>{{scope.row.currentNodeName}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="状态" align="center" width="250">
           <template slot-scope="scope">
            <span>{{ returnStatusName(scope.row.documentStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="220">
          <template slot-scope="scope">
            <el-button v-if="scope.row.documentStatus == 2" @click="selectAssign(scope.row)" type="text" size="small">{{scope.row.isAssign?'修改指派人':'指派审批人'}}</el-button>
            <el-button v-if="scope.row.documentStatus != 2" type="text" size="small" disabled >{{scope.row.isAssign?'修改指派人':'指派审批人'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="form[this.currentTabsNav].total > 10"
        @pageChange="pageChange"
        :total="form[this.currentTabsNav].total"
        :currentPage="form[this.currentTabsNav].pageNum"
        :pageSize="form[this.currentTabsNav].pageSize"
      ></pagination>
    </div>

      <div v-show="currentTabsNav === 1">
      <el-table
        ref="multipleTable"
        stripe
        :data="tableDataTwo"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column header-align="center" label="公文标题">
          <template slot-scope="scope">
            <span class="nf-tag nf-tag-jinji" v-if="scope.row.emergencyLevel == '1' || scope.row.emergencyLevel == '2'">{{scope.row.emergencyLevel == '1' ? '急' : '紧急'}}</span>
            <span  @click="toDetails(scope.row.id, scope.row.documentType, scope.row.documentArea, scope.row.documentStatus)" class="table-cont-title">{{ scope.row.documentTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="当前办文节点" align="center" width="250">
          <template slot-scope="scope">
            <span>{{scope.row.currentNodeName}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="状态" align="center" width="250">
           <template slot-scope="scope">
            <span>{{ returnStatusName(scope.row.documentStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="220">
           <template slot-scope="scope">
            <el-button v-if="scope.row.documentStatus == 2" @click="selectAssign(scope.row)" type="text" size="small">{{scope.row.isAssign?'修改指派人':'指派审批人'}}</el-button>
            <el-button v-if="scope.row.documentStatus != 2" type="text" size="small" disabled >{{scope.row.isAssign?'修改指派人':'指派审批人'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="form[this.currentTabsNav].total > 10"
        @pageChange="pageChange"
        :total="form[this.currentTabsNav].total"
        :currentPage="form[this.currentTabsNav].pageNum"
        :pageSize="form[this.currentTabsNav].pageSize"
      ></pagination>
    </div>

  </div>
    </div>
     <el-dialog title="删除原因" :visible.sync="isDialog" width="30%">
      <el-input
        type="textarea"
        :rows="3"
        v-model="reason_text"
       >
      </el-input>
      <div slot="footer" class="dialog-footer text_center">
        <el-button type="primary" @click="isDialog = false">确定</el-button>
      </div>
    </el-dialog>
    <modal-detail v-if="dialogVisible"  :assignObj="assignObj" v-model="dialogVisible" @refresh="getSearch"/>
  </div>
</template>
<!--popperSelect-->
<script>
import { Table, Select, MessageBox } from 'element-ui';
import pagination from '@/component/pagination/index.vue';
import TabNav from './component/tab-nav';
import detailModel from './component/assign-model.vue'
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
export default {
  components: {
    pagination,
    nfBreadcrumb,
    TabNav,
     'modal-detail':detailModel
  },
  created() {
    this.getList(); // 全部公文
    this.getList(1); // 已审批公文
    this.getIsAdmin();
    this.getBacklog()
  },
  data() {
    return {
      dialogVisible: false, // 指派审批人
      currentTabsNav: 0,
      tableDataOne: [],
      tableDataTwo: [],
      tableDataThree: [],
      form:[
        { pressing: '', status: '', keyword:'', pageNum:1, pageSize:10, total:0 },
        { pressing: '', status: '', keyword:'', pageNum:1, pageSize:10, total:0 }
      ],
      isDialog: false,
      reason_text: '',
      assignObj:{
        id:'',
        currentCodeInfo:{},
        record:[]
      },
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "移动办公"
        }
      ],
      backlog:'',
      activeNav: 4,
      isAssign: false,
      tabsNav: [
        {
          name: "待办管理",
          url: "awaitDocumentText"
        },
        {
          name: "办文管理",
          url: "documentText"
        },
        {
          name: "拟文管理",
          url: "proposedText"
        },
        {
          name: "全部公文",
          url: "allOfficial"
        },
        {
          name: "指派管理",
          url: "assign"
        }
      ],
    };
  },
  watch: {
    value(val) {
      this.group(val);
    }
  },
  methods: {
    async getBacklog() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.backlog = data.result.total;
        this.$store.commit('render', this.backlog);
      }
    },
    async getIsAdmin () {
      const api = this.$fetchApi.isAdministrators.api;
      const data = await this.$fetchData.fetchGet({}, api, 'json');
      if (data.code == '200') {
        this.isAssign =   data.result;
      }
    },
    onSubmit() {
      this.form[this.currentTabsNav].pageNum = 1;
      this.getList(); // 全部公文
    }, 
    // 指派完
    getSearch() {
      this.getList();
    },
    // 选择审批人 
    async selectAssign(row) {
       // 详情回显
      const params = {
        documentType: row.documentType,
        id: row.id,
        isIgnoreOpinion: 0
      };
      const api = this.$fetchApi.findApplyDetails.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if(data.code == '200') {
        this.assignObj.record =  data.result.recordListResps;
        this.assignObj.id = row.id;
        this.assignObj.currentCodeInfo = data.result.currentCodeInfo;
        this.dialogVisible = true;
      }
    },
    //  获取列表数据
    async getList(index) {
      index = index || this.currentTabsNav;
      const param = {
        pageNum:  this.form[index].pageNum,
        pageSize: this.form[index].pageSize,
        documentStatus: this.form[index].status || null, //公文状态 2-已发送，3-已办结，4-已驳回，5-已删除
        emergencyLevel: this.form[index].pressing || null, //紧急程度(0-一般，1-急，2-紧急)
        keyWord: this.form[index].keyword || null,
        type: index == 0?'1':'2' //查询的列表类型：1-全部公文，2-已指派公文
      }
      const api = this.$fetchApi.managerDocList.api;
      const data = await this.$fetchData.fetchGet(param, api);
      if (data.code === '200') {
        if(index == 0){
           this.tableDataOne = data.result.rows;
        }else if(index == 1) {
           this.tableDataTwo = data.result.rows;
        }
        this.form[index].total = data.result.total;
      }
    },
    async toDetails(id, code, area, status) {
      if (status == '5') {
        const params = {
          documentType: code,
          id: id
        };
        const api = this.$fetchApi.findApplyDetails.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        this.isDialog = true
        this.reason_text = data.result.documentDetail.deleteReason || '无'
        return 
      }
      this.$router.push('/formDetails?id=' + id + '&code=' + code + '&type=' + 'proposed');
    },
    // 根据状态返回名称
    returnStatusName(status) {
      if (status === 1) {
        return '草稿箱';
      } else if (status === 2) {
        return '已发送';
      } else if (status === 3) {
        return '已同意';
      } else if (status === 4) {
        return '已驳回';
      } else if (status === 5) {
        return '已删除';
      }
    },
    // 分页器
    pageChange(item) {
      this.form[this.currentTabsNav].pageNum = item.page_index;
      this.form[this.currentTabsNav].pageSize = item.page_limit;
      this.getList(this.currentTabsNav);
    },
     // 点击切换收文发文列表
    switchNav(data) {
      this.currentTabsNav = data;
    }
  }
};
</script>
<style lang="less" scoped>
.operate {
  padding: 20px;
}
.nf-tag {
  display: inline-block;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;

  &-jinji {
    background: #f56c6c;
  }

  &-ji {
    background: #e6a23c;
  }

  &-yiban {
    background: #909399;
  }
}
.table-cont-title {
  cursor: pointer;
}
.el-select {
  margin-bottom: 10px;
}
.text_center {
  text-align: center;
}

.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
    &-all {
      background-image: url("../../assets/images/officialDocument/icon_all.png");
    }
    &-zp {
      background-image: url("../../assets/images/officialDocument/icon_zp.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
