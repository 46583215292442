<template>
  <div class="modal-detail">
    <el-dialog  title="指派审批人" :visible.sync="dialogDetailVisible" center lock-scroll :close-on-click-modal="false"  >
    <el-form >
      <el-form-item  label="审批节点记录:" :label-width="formLabelWidth" class="font-bold">
        <div style="line-height:20px;margin:10px 10px 10px;">
          <el-steps direction="vertical" :active="assignObj.record.length +1">
            <el-step v-for="(item,index) in assignObj.record" :key="index" :title="`${item.nodeName}-${item.realName}`">
              <template #description>
                <div>
                  <!-- <el-radio label="1" disabled>111</el-radio>
                  <el-radio label="2" disabled>11321</el-radio>
                  <el-radio label="2" disabled >131</el-radio> -->
                  <p class="reject font-gray">{{returnStatusName(item.approveStatus)}}  {{item.approveDate}}</p>
                </div>
              </template>
            </el-step>
            <el-step :title="`${assignObj.currentCodeInfo.nodeName}-${assignObj.currentCodeInfo.handleMemberName}`">
              <template #description>
                <div>
                  <p class="reject">待审批</p>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
        
      </el-form-item>

      <div>
          <el-divider ></el-divider>
            <el-form-item style="margin-top:10px" label="当前审批节点" :label-width="'200px'" >
                <el-input  :value="`${assignObj.currentCodeInfo.nodeName}-${assignObj.currentCodeInfo.handleMemberName}`"   readonly style="width:320px;"></el-input>
            </el-form-item>
            <el-form-item style="margin-top:10px" label="指派当前节点审批人" :label-width="'200px'" >
                <el-select v-model="param.assignMemberId" filterable placeholder="请选择" style="width:320px;">
                  <el-option
                    v-for="item in selectOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
      </div>
    </el-form>
    
  <div slot="footer"  class="dialog-footer" style="margin-top:30px">
    <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
  </div>
  
</el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    assignObj:{ type: Object, default: () => {} },
    value:{ type: Boolean ,default: () => true },
  },
  components:{
  },
  name:'modal-input',
  data() {
    return {
      dialogDetailVisible: this.value,
      formLabelWidth: '200px',
      selectOption: [],
      param:{
        assignMemberId:'',
        documentId: this.assignObj.id
      },
      
      status:{
        0:'未开始',
        1:'审批中',
        2:'已通过',
        3:'已驳回' ,
        4:' 已取消'
      },
    }
  },
  created() {
    this.getDispatchMemberList();
  },
  methods:{
    //  获取审批的对象
     async getDispatchMemberList() {
      const api = this.$fetchApi.getDispatchMemberList.api;
      const data = await this.$fetchData.fetchPost({}, api, 'json');
      if (data.code === '200' && data.result.length) {
        data.result.forEach(el=>{
          if(el.children.length){
            el.children.forEach(item => {
              if(item.children.length){
                item.children.forEach(i =>{
                   this.selectOption.push({
                      name:`${el.name}-${item.name}-${i.name}`,
                      id:i.id
                    })
                })
              }
            });
          }
        })
      }
    },
    //  提交修改
    async onSubmit() {
      // if(this.param.assignMemberId) {
      //   this.$message.warning('指派人不能为空');
      //   return false;
      // }
      const api = this.$fetchApi.assignDoc.api;
      const data = await this.$fetchData.fetchPost(this.param, api,'json');
      if(data.code === '200'){
        this.$message.success('指派成功!')
        this.$emit('refresh');
        this.$emit('input',false)
      }
    },
    returnStatusName(status) {
      if (status === 1) {
        return '拟稿';
      } else if (status === 2) {
        return '已发送';
      } else if (status === 3) {
        return '已同意';
      } else if (status === 4) {
        return '已驳回';
      } else if (status === 5) {
        return '已删除';
      }
    }
  },
  watch:{
    value(newValue) {
      this.dialogDetailVisible = newValue;
    },
    id(newValue) {
      if(!newValue) return false;
      this.initData();
    },
    dialogDetailVisible(newValue){
      this.$emit('input', newValue);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.text-center{
  text-align: center;
}
.reject{
  font-size: 14px;
  padding-left: 10px;
  line-height: 26px;
}
/deep/.el-step.is-vertical .el-step__main{
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  padding-bottom: 30px;
}
.font-red{
  color: #f4511e;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #f4511e;
  }
}
.font-green {
  color: #67c23a;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #67c23a;
  }
}
.font-gray {
  color: #666;
}
.font-blue {
  color: #409eff;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #409eff;
  }
}
.agree{
  text-align: center!important;
  height: 30px;
  line-height: 6px;
}
.agree.is-bordered.is-checked{
  text-align: center;
  background-color: #85ce61 ;
  border-color: #85ce61;
  /deep/.el-radio__input.is-checked+.el-radio__label{
  color: #fff!important;
  }
}
.noagree.is-bordered.is-checked{
  border-color: #f4511e;
  background-color: #f4511e;
  /deep/.el-radio__input.is-checked+.el-radio__label{
  color: #fff!important;
  }
}
.modal-detail .el-radio{
  width: 80px;
  text-align: left;
}
.modal-detail /deep/.el-radio__input {
  display: none!important;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px ;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:0px
}
.font-bold{
  font-size: 18px;
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 0px;
} 
.el-tag{
  margin: 4px 10px;
}
.el-divider--horizontal{
  margin: 10px 0px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>